$kolor: #FCDE51;

.contrast_switcher{
  border: 0 none;
  background: transparent;
  margin: 0 -10px 0 15px;
  opacity: 0.7;
  &:focus{ outline: none; }
  &:hover{ opacity: 1; }
}
html.high_contrast{
  background-color: #000;
  body{ background-color: #000; }
  #root{
    header{
      background-color: #000;
      border-bottom: 1px solid #4e4e4e;
      .auth_info{ color: #fff; }
      button.logout{
        background-color: $kolor;
        color: #000;
        svg{ fill: #000; }
      }
      .logo{ background-color: #000; }
    }
    .breadcrumb{ background-color: #000; color: $kolor; }
    main{
      .login_form form h2{
        color: #000;
        border-bottom-color: #000;
      }
      .field{
        label{ color: #000; }
        input{ border-color: #000; }
        input:not(:placeholder-shown) + label, input:focus + label{
          font-weight: 600;
        }
      }
      .f_group label:before{ opacity: 1; }
      .f_group > div{ font-weight: 600; }
      .elem-list .elem-tile {
        font-weight: 600;
        label {
          color: #000;
          font-weight: 600;
          img{
            opacity: 1;
          }
        }
      }
      .product_suggestions span{ background-color: #000; color: #fff; }
      .searchBox{ border-color: #000; }
      .ramka {
        label, .label{
          color: #000;
          font-weight: 600;
        }
      }
      .pakowanie {
        .checkbox{
          border-color: #000;
          &.checked{
            background-color: #000;
            path{ fill: #00ff30 !important; }
          }
        }
        .pakowanie_order h4:before{ opacity: 1; }
        .pakowanie_products .ramka.produkt .default_code{ color: #000; }
        .pakowanie_products .ramka.produkt.last_scanned > div:before{ opacity: 1; }
      }
      div.done{
        button.print_invoice:before, button.print_label:before{ opacity: 1; }
      }
    }
    article[data-view="Order"] .pakowanie_header{
      background-color: #000;
      background: linear-gradient(180deg, #000 80%, rgba(255, 255, 255, 0) 100%);
    }
    footer{
      color: #000;
      .worldIcon, .contrast_switcher{ opacity: 1; }
      .selectArr path{ fill-opacity: 1 !important; }
      select.lang{ font-weight: 400; }
    }
  }
  thead tr,
  #root main button:not(.react-datepicker__navigation):not(.filepond--file-action-button),
  #root main .button,
  #root main input[type="submit"],
  #popupContainer .mm-popup button,
  #root footer,
  #root thead tr,
  #popupContainer .mm-popup__box__header,
  #root footer a, #root footer a:hover, #root footer a:active, #root footer a:visited
  {
    background-color: $kolor !important;
    color: #000 !important;
  }
  #root footer select.lang
  {
    color: #000 !important;
  }
  #root footer svg{
    fill: #000 !important;
  }
  #root main .elem-list .elem-tile:hover{
    border-color: $kolor !important;
  }
}
